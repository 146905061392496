<template>
  <div class="page-layout-wrapper">
    <div class="container">
      <PageHeader title="Stats" />

      <div v-if="loading">
        Loading stats...
      </div>

      <div v-else>
        <table class="table">
          <thead>
            <tr>
              <th>IP Address</th>
              <th>Visitor</th>
              <th>Action</th>
              <th>Occurred On</th>
            </tr>
          </thead>
          <tbody>
          <tr v-for="(record, i) in stats" :key="i">
            <td>{{ record.visitor.ip_address }}</td>
            <td>
              {{ record.visitor.name ? record.visitor.name : 'Guest' }}
              <span v-if="record.visitor.email">
                &lt;<a :href="'mailto:' + record.visitor.email" v-text="record.visitor.email"></a>&gt;
              </span>
              <br/>
              {{ record.visitor.location || '-' }}
            </td>
            <td>
              <span v-if="record.action.name == 'song_downloaded'">
                Downloaded <router-link :to="'/admin/songs/'+record.action.song.id">'{{ record.action.song.title }}' by '{{ record.action.song.artist }}'</router-link>
              </span>
              <span v-else-if="record.action.name == 'song_played'">
                Played <router-link :to="'/admin/songs/' + record.action.song.id">'{{ record.action.song.title }}' by '{{ record.action.song.artist }}'</router-link>
              </span>
              <span v-else-if="record.action.name == 'song_searched'">
                {{ record.action.label }}
              </span>
              <span v-else>
                {{ record.action.name }}
              </span>
            </td>
            <td>{{ record.created_at }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import PageHeader from "@/components/Page/PageHeader";

export default {
  name: 'Stats',
  components: {
    PageHeader
  },
  data: () => ({
    loading: false,
    stats: [],
    currentPage: 1,
    lastPage: 1
  }),
  created() {
    this.loading = true;
    this.$http
        .get('/admin/stats')
        .then(({ data }) => {
          this.loading = false;
          this.stats = data.data.map((record) => {
            record.created_at = moment(String(record.created_at)).format('lll');
            return record;
          });
          this.currentPage = data.current_page;
          this.lastPage = data.last_page;
        });
  }
}
</script>
